
// @ts-nocheck


export const localeCodes =  [
  "En",
  "Es"
]

export const localeLoaders = {
  En: [
    {
      key: "locale_en_46json_a8d1fe5f",
      load: () => import("#nuxt-i18n/a8d1fe5f" /* webpackChunkName: "locale_en_46json_a8d1fe5f" */),
      cache: true
    },
    {
      key: "locale_en_46json_a8d1fe5f",
      load: () => import("#nuxt-i18n/a8d1fe5f" /* webpackChunkName: "locale_en_46json_a8d1fe5f" */),
      cache: true
    }
  ],
  Es: [
    {
      key: "locale_es_46json_3915b454",
      load: () => import("#nuxt-i18n/3915b454" /* webpackChunkName: "locale_es_46json_3915b454" */),
      cache: true
    },
    {
      key: "locale_es_46json_3915b454",
      load: () => import("#nuxt-i18n/3915b454" /* webpackChunkName: "locale_es_46json_3915b454" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "En",
      iso: "En",
      name: "English",
      files: [
        "/app/apps/onboarding/i18n/locales/en.json",
        "/app/apps/onboarding/i18n/locales/en.json"
      ]
    },
    {
      code: "Es",
      iso: "Es",
      name: "Español",
      files: [
        "/app/apps/onboarding/i18n/locales/es.json",
        "/app/apps/onboarding/i18n/locales/es.json"
      ]
    }
  ],
  defaultLocale: "En",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "c1_lang",
    cookieSecure: false,
    fallbackLocale: "En",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "En",
    iso: "En",
    name: "English",
    files: [
      {
        path: "/app/apps/onboarding/i18n/locales/en.json",
        cache: undefined
      },
      {
        path: "/app/apps/onboarding/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "Es",
    iso: "Es",
    name: "Español",
    files: [
      {
        path: "/app/apps/onboarding/i18n/locales/es.json",
        cache: undefined
      },
      {
        path: "/app/apps/onboarding/i18n/locales/es.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/