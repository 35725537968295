import revive_payload_client_VDvb6L6CcHSr9Vw7PLpmbCLTgf_gcNXYDyG7TTWqOMk from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_O10QoVQZfZyJN920_OzqJABw4_vYvIwt4j3dw8bmU00 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N2LEh0CIPcKM9tApzw_XdMFa34dlcn4Xp_lmJwLXI50 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YIkLLfjx_wNaBhAmK4YPblkaEXj8xVAllK77lySLocg from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_s3aZUSpPSN8G7Bsq1d_d6hCCi3Jvsq_Kw00SXktq7r8 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_akjPFm_1bu5i_kzrD0r_z2RrRnlugKm4qKizt85ROdQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_XYnGJdpDh8twvJeWskdxfL_gkhld2gXaRcFtcucGxa0 from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client__VurVr6YRC2sMS5Tt5oVdXrFiefHmi2vTN9rvFS4LPA from "/app/node_modules/.pnpm/nuxt-viewport@2.2.0_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr__e7wbTYt7GRgxg3bnTQHga_0OnaFbQtGPwdG77cwFMY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_XlqsJGoVIVOJFig_pLgS4atZcsNh5bhf0BDSNrbjm18 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_x8z86hR2TaynvlhKsNRH_91JLPTTDQV5zQL2nsicrL0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_K4TZ5_FaVniyB3sWVft_JLxgKsIrQBawlbB0Y8n3lWU from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.1_@parcel+watcher@2.5.1_@type_bebd3416aa518f2d158687992f6ddbb9/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import _1_gigya_plugin_client_drecDJBDoqcGVDhLJlL6oghgZio6Oh2IsJqAvLlujIg from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client__5RDvQizX_G_dHWpHEukuamfGPrBPIRwGxgfuMShf30 from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_CmvsMt2yc179anUrkMpu2ro1FOusxOPjKTzO9_jIoEE from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_53vV44b_crcr3YUhm_3qWX9gw2p4Iv1dvAzsvUWQKXc from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_X8gr8X2qYEGsJXvxj0op3gP1ixelV6JjXjVtapQ1kgE from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_LEnUne5YubkD_Wwe79pKejYpZOqwYlUtnki0H6vSW2E from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import app_lifecycle_client_ldmRutHao038yv_0jKgNXyCTpaPhAsE093wYxx1nieU from "/app/apps/onboarding/plugins/app-lifecycle.client.ts";
import image_thumbnail_update_4AkIuesub63PksE5vZwyiV_iG0J0tgpLXK_vYt4GZqY from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_2uuprcc6n_lG8cqmNSXpDe77tgNyeGI_bBRCQMvDsu8 from "/app/apps/onboarding/plugins/page-view-event.client.ts";
import ssg_detect__3TLMZDyifq4IYqxytslzfrPTXIWOFQVtV8N_tk8N5c from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_8739fac6bfb843af9ca5122dc31e8d19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_VDvb6L6CcHSr9Vw7PLpmbCLTgf_gcNXYDyG7TTWqOMk,
  unhead_O10QoVQZfZyJN920_OzqJABw4_vYvIwt4j3dw8bmU00,
  router_N2LEh0CIPcKM9tApzw_XdMFa34dlcn4Xp_lmJwLXI50,
  payload_client_YIkLLfjx_wNaBhAmK4YPblkaEXj8xVAllK77lySLocg,
  navigation_repaint_client_s3aZUSpPSN8G7Bsq1d_d6hCCi3Jvsq_Kw00SXktq7r8,
  chunk_reload_client_akjPFm_1bu5i_kzrD0r_z2RrRnlugKm4qKizt85ROdQ,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_XYnGJdpDh8twvJeWskdxfL_gkhld2gXaRcFtcucGxa0,
  plugin_client__VurVr6YRC2sMS5Tt5oVdXrFiefHmi2vTN9rvFS4LPA,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  switch_locale_path_ssr__e7wbTYt7GRgxg3bnTQHga_0OnaFbQtGPwdG77cwFMY,
  route_locale_detect_XlqsJGoVIVOJFig_pLgS4atZcsNh5bhf0BDSNrbjm18,
  i18n_x8z86hR2TaynvlhKsNRH_91JLPTTDQV5zQL2nsicrL0,
  plugin_K4TZ5_FaVniyB3sWVft_JLxgKsIrQBawlbB0Y8n3lWU,
  _1_gigya_plugin_client_drecDJBDoqcGVDhLJlL6oghgZio6Oh2IsJqAvLlujIg,
  _2_user_session_validation_client__5RDvQizX_G_dHWpHEukuamfGPrBPIRwGxgfuMShf30,
  bootstrap_client_CmvsMt2yc179anUrkMpu2ro1FOusxOPjKTzO9_jIoEE,
  focus_visible_client_53vV44b_crcr3YUhm_3qWX9gw2p4Iv1dvAzsvUWQKXc,
  http_interceptor_X8gr8X2qYEGsJXvxj0op3gP1ixelV6JjXjVtapQ1kgE,
  _0_gigya_plugin_client_LEnUne5YubkD_Wwe79pKejYpZOqwYlUtnki0H6vSW2E,
  app_lifecycle_client_ldmRutHao038yv_0jKgNXyCTpaPhAsE093wYxx1nieU,
  image_thumbnail_update_4AkIuesub63PksE5vZwyiV_iG0J0tgpLXK_vYt4GZqY,
  page_view_event_client_2uuprcc6n_lG8cqmNSXpDe77tgNyeGI_bBRCQMvDsu8,
  ssg_detect__3TLMZDyifq4IYqxytslzfrPTXIWOFQVtV8N_tk8N5c
]