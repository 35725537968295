import validate from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.10.7_db0@0.3.1_eslint@9.23.0_jiti@2.4._21fe0e9b17aee21747166f12735e49c4/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "check-authenticated": () => import("/app/layers/base/middleware/check-authenticated.ts"),
  "common-loader": () => import("/app/apps/onboarding/middleware/common-loader.ts"),
  "lti-onboarding": () => import("/app/apps/onboarding/middleware/lti-onboarding.ts"),
  "page-redirection": () => import("/app/apps/onboarding/middleware/page-redirection.ts"),
  "parent-login": () => import("/app/apps/onboarding/middleware/parent-login.ts"),
  "redirect-home": () => import("/app/apps/onboarding/middleware/redirect-home.ts")
}